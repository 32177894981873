<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Add New Podata</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <form ref="observer" tag="form" @submit.prevent="submitForm()">
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Ponum 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlponum" v-model.trim="formData.ponum"  label="Ponum" type="text" placeholder="Enter Ponum"      
                                                    class=" w-full" :class="getErrorClass('ponum')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('ponum')" class="p-error">{{ getFieldError('ponum') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Releasenum 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlreleasenum" v-model.trim="formData.releasenum"  label="Releasenum" type="number" placeholder="Enter Releasenum"   step="any"    
                                                    class=" w-full" :class="getErrorClass('releasenum')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('releasenum')" class="p-error">{{ getFieldError('releasenum') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Linenum 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrllinenum" v-model.trim="formData.linenum"  label="Linenum" type="number" placeholder="Enter Linenum"   step="any"    
                                                    class=" w-full" :class="getErrorClass('linenum')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('linenum')" class="p-error">{{ getFieldError('linenum') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Del To 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrldel_to" v-model.trim="formData.del_to"  label="Del To" type="text" placeholder="Enter Del To"      
                                                    class=" w-full" :class="getErrorClass('del_to')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('del_to')" class="p-error">{{ getFieldError('del_to') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Del To2 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrldel_to2" v-model.trim="formData.del_to2"  label="Del To2" type="text" placeholder="Enter Del To2"      
                                                    class=" w-full" :class="getErrorClass('del_to2')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('del_to2')" class="p-error">{{ getFieldError('del_to2') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Del Locid 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrldel_locid" v-model.trim="formData.del_locid"  label="Del Locid" type="text" placeholder="Enter Del Locid"      
                                                    class=" w-full" :class="getErrorClass('del_locid')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('del_locid')" class="p-error">{{ getFieldError('del_locid') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Del Loccod 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrldel_loccod" v-model.trim="formData.del_loccod"  label="Del Loccod" type="text" placeholder="Enter Del Loccod"      
                                                    class=" w-full" :class="getErrorClass('del_loccod')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('del_loccod')" class="p-error">{{ getFieldError('del_loccod') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Orgid 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlorgid" v-model.trim="formData.orgid"  label="Orgid" type="text" placeholder="Enter Orgid"      
                                                    class=" w-full" :class="getErrorClass('orgid')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('orgid')" class="p-error">{{ getFieldError('orgid') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Org Code 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlorg_code" v-model.trim="formData.org_code"  label="Org Code" type="text" placeholder="Enter Org Code"      
                                                    class=" w-full" :class="getErrorClass('org_code')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('org_code')" class="p-error">{{ getFieldError('org_code') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Creatdate 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <Calendar :showButtonBar="true" :showTime="true" dateFormat="yy-mm-dd" hourFormat="24" v-model="formData.creatdate" :showIcon="true" class="w-full" :class="getErrorClass('creatdate')"     mask="yy-MM-DD HH:mm"     />
                                                    <small v-if="isFieldValid('creatdate')" class="p-error">{{ getFieldError('creatdate') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Vendor 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlvendor" v-model.trim="formData.vendor"  label="Vendor" type="text" placeholder="Enter Vendor"      
                                                    class=" w-full" :class="getErrorClass('vendor')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('vendor')" class="p-error">{{ getFieldError('vendor') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Item No *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlitem_no" v-model.trim="formData.item_no"  label="Item No" type="text" placeholder="Enter Item No"      
                                                    class=" w-full" :class="getErrorClass('item_no')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('item_no')" class="p-error">{{ getFieldError('item_no') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Line D 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlline_d" v-model.trim="formData.line_d"  label="Line D" type="text" placeholder="Enter Line D"      
                                                    class=" w-full" :class="getErrorClass('line_d')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('line_d')" class="p-error">{{ getFieldError('line_d') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Price 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlprice" v-model.trim="formData.price"  label="Price" type="number" placeholder="Enter Price"   step="0.1"    
                                                    class=" w-full" :class="getErrorClass('price')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('price')" class="p-error">{{ getFieldError('price') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Qty 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlqty" v-model.trim="formData.qty"  label="Qty" type="text" placeholder="Enter Qty"      
                                                    class=" w-full" :class="getErrorClass('qty')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('qty')" class="p-error">{{ getFieldError('qty') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Uom 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrluom" v-model.trim="formData.uom"  label="Uom" type="text" placeholder="Enter Uom"      
                                                    class=" w-full" :class="getErrorClass('uom')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('uom')" class="p-error">{{ getFieldError('uom') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Dater 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <Calendar :showButtonBar="true" :showTime="true" dateFormat="yy-mm-dd" hourFormat="24" v-model="formData.dater" :showIcon="true" class="w-full" :class="getErrorClass('dater')"     mask="yy-MM-DD HH:mm"     />
                                                    <small v-if="isFieldValid('dater')" class="p-error">{{ getFieldError('dater') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Selopt 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlselopt" v-model.trim="formData.selopt"  label="Selopt" type="text" placeholder="Enter Selopt"      
                                                    class=" w-full" :class="getErrorClass('selopt')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('selopt')" class="p-error">{{ getFieldError('selopt') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Po Line Amount 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlpo_line_amount" v-model.trim="formData.po_line_amount"  label="Po Line Amount" type="number" placeholder="Enter Po Line Amount"   step="0.1"    
                                                    class=" w-full" :class="getErrorClass('po_line_amount')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('po_line_amount')" class="p-error">{{ getFieldError('po_line_amount') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Submit" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, numeric, } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useAddPage } from '@/composables/addpage.js';
	const props = defineProps({
		pageName : {
			type : String,
			default : 'podata',
		},
		routeName : {
			type : String,
			default : 'podataadd',
		},
		apiPath : {
			type : String,
			default : 'podata/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Submit",
		},
		msgAfterSave: {
			type: String,
			default: "Record added successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => {} 
		},
	});
	const app = useApp();
	const formDefaultValues = {
		ponum: "", 
		releasenum: "", 
		linenum: "", 
		del_to: "", 
		del_to2: "", 
		del_locid: "", 
		del_loccod: "", 
		orgid: "", 
		org_code: "", 
		creatdate: "", 
		vendor: "", 
		item_no: "", 
		line_d: "", 
		price: "", 
		qty: "", 
		uom: "", 
		dater: "", 
		selopt: "", 
		po_line_amount: "", 
	}
	const formData = reactive({ ...formDefaultValues });
	// perform custom validation before submit form
	// set custom form fields
	// return false if validation fails
	function beforeSubmit(){
		return true;
	}
	// after form submited to api
	// reset form data.
	// redirect to another page
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterSave);
		Object.assign(formData, formDefaultValues); //reset form data
		if(props.redirect) app.navigateTo(`/podata`);
	}
	//form validation rules
	const rules = computed(() => {
		return {
			ponum: {  },
			releasenum: { numeric },
			linenum: { numeric },
			del_to: {  },
			del_to2: {  },
			del_locid: {  },
			del_loccod: {  },
			orgid: {  },
			org_code: {  },
			creatdate: {  },
			vendor: {  },
			item_no: { required },
			line_d: {  },
			price: { numeric },
			qty: {  },
			uom: {  },
			dater: {  },
			selopt: {  },
			po_line_amount: { numeric }
		}
	});
	const v$ = useVuelidate(rules, formData); // form validation
	const page = useAddPage({ props, formData, v$, onFormSubmited, beforeSubmit });
	//page state
	const {
		submitted, // form submitted state - Boolean
		saving, // form api submiting state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page methods
	const {
		submitForm, //submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Add New Podata";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>
